.arbejdsbskrivelser {
  & .ant-select-selection-overflow-item {
    flex: 1 0 100% !important;

    & .ant-select-selection-item {
      justify-content: space-between;
      background: #f6ffed;
      border: 1px solid #b7eb8f;
    }
  }
}
